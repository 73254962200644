::selection {
  color: black;
  background: #ee6b4c;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.p-0 {
  padding: 0px;
}
/* 
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}

.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}

.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
} */
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.c-ant-card-hoverable {
  transition: box-shadow 0.3s, border-color 0.3s;
}
.c-ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
    rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
}
.c-products-list-item {
  color: #1a3353 !important;
}
.c-products-list-item:hover {
  color: #3e79f7;
}
.c-pointer {
  cursor: pointer;
}
.c-selected-card {
  border: 2px solid rgb(98 173 82);
  box-shadow: rgba(98, 173, 82, 0.16) 0px 1px 2px -2px, rgba(98, 173, 82, 0.12) 0px 3px 6px 0px,
    rgba(98, 173, 82, 0.09) 0px 5px 12px 4px;
  background-color: rgba(98, 173, 82, 0.04);
}
.ant-form-item-explain {
  font-size: 12px !important;
  color: rgb(158 166 177);
  font-style: italic;
}
[class*="globalClass_"] [class*="logo_"],
[class*="leaf_"] [class*="cssLeaf_"],
.leaf_d36d._left_a113 .cssLeaf_b69c,
.leaf_d36d._right_d626 .cssLeaf_b69c {
  display: none !important;
}
[class*="globalClass_"] [class*="label_"] [class*="_bottom_"] {
  min-width: 100px !important;
}
[class*="globalClass_"] [class*="label_"][class*="_bottom_"],
[class*="globalClass_"] [class*="label_"][class*="_right_"] {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
[class*="globalClass_"] [class*="label_"][class*="_bottom_"] [class*="text_"],
[class*="globalClass_"] [class*="label_"][class*="_left_"] [class*="text_"],
[class*="globalClass_"] [class*="label_"][class*="_right_"] [class*="text_"] {
  margin: 0 10px 0 10px !important;
  min-width: 100px;
}
[class*="globalClass_"] [class*="leaf_"][class*="_bottom_"],
[class*="globalClass_"] [class*="leaf_"][class*="_left_"],
[class*="globalClass_"] [class*="leaf_"][class*="_right_"] {
  width: 0px !important;
}
[class*="globalClass_"] [class*="copy_"] {
  display: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
[class*="globalClass_"] [class*="label_"] [class*="text_"] {
  line-height: 28px !important;
}
[class*="globalClass_"] [class*="label_"] {
  height: 25px !important  ;
}
[class*="globalClass_"] .__jivoMobileButton {
  zoom: 0.7 !important;
}
[class*="globalClass_"] #jvlabelWrap {
  bottom: 130px !important;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.loader-logo {
  text-align: center;
  margin-top: 60px;
}
.loader {
  margin: 20px auto 25px auto;
  font-size: 11px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid rgba(110, 110, 110, 0.2);
  border-right: 0.8em solid rgba(110, 110, 110, 0.2);
  border-bottom: 0.8em solid rgba(110, 110, 110, 0.2);
  border-left: 0.8em solid #868686;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.auth-page {
  background-image: url(/public/img/auth_background.png);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fadeIn 0.8s;
  opacity: 1;
}
.auth-page-xmas {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* animation: fadeIn 0.8s; */
  opacity: 1;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loader-msg {
  font-size: 14px;
  color: #8d8d8d;
  text-align: center;
  text-transform: uppercase;
}
.loader-msg-xmas {
  font-size: 20px;
  font-weight: 600;
  color: #8d8d8d;
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
}
.loader-version {
  font-size: 10px;
  color: #c9c9c9;
  text-align: center;
  text-transform: uppercase;
}
body {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
/* Safari, Chrome and Opera > 12.1 */
/* Internet Explorer */
/* Opera < 12.1 */
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-bottom: 0px !important;
  border-radius: 0.225rem 0.225rem 0px 0px !important;
}
.ql-editor {
  min-height: 250px;
  overflow: visible !important;
}
.ql-container {
  border-radius: 0px 0px 0.225rem 0.225rem !important;
}
.ql-tooltip {
  left: 0 !important;
}
.pulsingButton {
  box-shadow: 0 0 0 0 rgba(62, 121, 247, 0.4);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}
.pulsingButton:hover {
  animation: none;
}
.pulsingButtonOnboarding {
  animation: pulseOnboarding 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 rgba(0, 132, 255, 0.4);
}
.pulsingButtonOnboarding:hover {
  animation: none;
}
.pulsingButtonSmall {
  box-shadow: 0 0 0 0 rgba(62, 121, 247, 0.4);
  animation: pulseSmall 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}
.pulsingButtonSmall:hover {
  animation: none;
}
.pulsingButtonViewHelpVideo {
  animation: pulseViewHelpVideo 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 rgba(255, 107, 114, 0.4);
  border-radius: 50%;
  width: 16px;
  height: 16px;
}
.pulsingButtonSalesParkIgnite {
  animation: pulseViewHelpVideo 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 rgba(255, 107, 114, 0.4);
  border-radius: 60px;
  width: 100%;
  font-weight: bold;
}
.btn-help-video {
  z-index: 1;
  background-color: #ffeeea !important;
  border: 1px solid #ffd8da !important;
}
.btn-help-video:hover {
  background-color: #fdd4ca !important;
  border: 1px solid #fdbfaf !important;
}
/* Animation */
@keyframes pulse {
  to {
    box-shadow: 0 0 0 25px rgba(0, 153, 255, 0);
  }
}
@keyframes pulseOnboarding {
  to {
    box-shadow: 0 0 0 10px rgba(0, 153, 255, 0);
  }
}
@keyframes pulseSmall {
  to {
    box-shadow: 0 0 0 15px rgba(0, 153, 255, 0);
  }
}
@keyframes pulseViewHelpVideo {
  to {
    box-shadow: 0 0 0 10px rgba(255, 107, 114, 0);
  }
}
.clickable_link {
  cursor: pointer;
  color: #3e79f7;
}
.clickable_link:hover {
  cursor: pointer;
  color: #4c82f5;
}
.clickable,
.clickable_hover {
  cursor: pointer;
}
.clickable_hover:hover {
  cursor: pointer;
  color: #3e79f7 !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.ant-upload.ant-upload-drag {
  min-height: 180px !important;
}
.course-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.course-cover img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background-position: center;
}
.color-picker .color-picker-dropdown .color {
  height: 25px !important;
  width: 25px !important;
}
.color-picker .color-picker-dropdown {
  margin-right: 8px !;
}
.product-managment-rules {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-item-limiter {
  width: inherit;
  max-width: 630px;
}
.form-item-limiter .ant-form-item-control-input-content {
  max-width: 630px;
}
.clickable {
  cursor: pointer;
}
.page-header-title {
  margin-top: 0px !important;
  font-size: 18px;
  margin-bottom: 90px !important;
}
/**** TIMELINE OVERRIDE ****/
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(20% - 12px) !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  left: 20% !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(20% - 4px) !important;
  width: calc(80% - 14px) !important;
}
.sms-editor-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 354px;
}
#noticeable-widget iframe {
  top: -20px !important;
  right: 9px !important;
}
.widget-header-main-title {
  margin-top: 20px !important;
}
.bb-feedback-button .bb-logo-logo {
  width: 30px !important;
  height: 30px !important;
  top: 10px !important;
  left: 10px !important;
}
.bb-feedback-button-icon {
  width: 50px !important;
  height: 50px !important;
}
.bb-feedback-button-icon .bb-logo-arrowdown {
  width: 24px !important;
  height: 24px !important;
  top: 16px !important;
  left: 13px !important;
}
/********************************************************************************/
/* UPDATE NOTIFICATION */
.update-notification-popup {
  position: fixed;
  bottom: 24px;
  left: 24px !important;
  box-shadow: 0px 0px 20px 6px rgba(184, 184, 184, 0.5);
  border-radius: 6px;
  background-color: #fff;
  padding: 18px;
  display: flex;
  max-width: calc(100vw - 48px);
  width: 300px;
  z-index: 9999;
  background-image: url(/public/img/update_background.png);
  background-size: cover;
  border: 2px solid #f5daee;
}
.update-notification-popup button {
  background-color: #2977f6;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 0 16px;
  font-size: 0.9rem;
  height: 34px;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}
.update-notification-popup button {
  cursor: pointer;
}
.update-notification-popup p {
  font-size: 0.8rem;
  line-height: 0.95rem;
}
.update-notification-popup svg {
  color: #cc85b3;
}
.product-links-transfer .ant-transfer-list {
  height: 300px;
  width: 45%;
}
.card-is-dragging {
  border: 2px dashed #ed6c4c !important;
}
/* .table-producers-online .ant-table-body {
  height: 1005px !important;
  max-height: 1005px !important;
} */
.salespark-notice {
  position: relative;
  left: -25px;
  width: calc(100% + 50px);
  top: -15px;
}
.salespark-notice .ant-alert {
  padding: 8px;
}
.salespark-notice .ant-alert-warning {
  background-color: #fff5ca !important;
  border-bottom: 1px solid #e7d68e !important;
}
.salespark-notice .ant-alert-error {
  background-color: #ffdfdc !important;
  border-bottom: 1px solid #f7a69f !important;
}
.salespark-notice .ant-alert-info {
  background-color: #e5f5ff !important;
  border-bottom: 1px solid #aae0ff !important;
}
.app-content-notice {
  padding-top: 15px !important;
}
.phoneOtpContainer {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.phoneOtpWaitContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.phoneOtpContainerTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.phoneOtpContainerInput {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;
}

.phoneOtp input {
  width: 3.2rem !important;
  height: 3.2rem !important;
  margin: 0 0.5rem !important;
  font-size: 2.5rem !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.phoneOtpContainerCountdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.phoneOtpContainerCountdown .timer-wrapper {
  display: flex;
  justify-content: center;
}
.phoneOtpContainerCountdown .timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.phoneOtpContainerCountdown .text {
  color: #aaa;
}
.phoneOtpContainerCountdown .value {
  font-size: 25px;
}
.phoneOtpContainerCountdown .info {
  max-width: 250px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 16px;
}
.phone-input .form-control {
  width: 100% !important;
  min-width: 0px !important;
  background-color: #ffffff !important;
  background-image: none !important;
  border: 1px solid #e6ebf1 !important;
  border-radius: 0.225rem !important;
  transition: all 0.3s ease 0s !important;
  display: inline-flex !important;
}
.smallTag {
  font-size: 10px !important;
  padding: 0px 5px !important;
  margin: 0px 5px 0px 8px !important;
  line-height: 20px !important;
}
.smallTag span.anticon {
  font-size: 12px;
}
.smallTagNoMargin {
  font-size: 10px;
  padding: 0px 5px;
  margin: 0px 2px 0px 2px;
  line-height: 20px;
}
.roundedTag {
  font-size: 10px;
  padding: 0px 6px;
  margin: 0px 5px 5px 0px;
  line-height: 20px;
  border-radius: 10px;
}
.custom-collapse-panel .ant-collapse-content-box {
  padding-left: 40px !important;
}
.custom-collapse-panel .ant-collapse-header {
  font-size: 14px;
  /* padding-bottom: 5px !important; */
}
.custom-collapse {
  border: 0px;
  overflow: hidden;
}
.custom-collapse-panel {
  background: #f7f7f7;
  border-radius: 4px !important;
  margin-bottom: 14px;
  border: 0px !important;
  overflow: hidden;
}
.custom-collapse-panel .ant-collapse-header {
  font-size: 14px;
}
.label-disabled > .ant-form-item-label > label,
.label-disabled {
  color: #d0d4d7 !important;
}
.work-tools-text .work-tools-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #505c6a;
}
.work-tools-text .work-tools-label {
  font-size: 0.9rem;
  font-weight: 400;
  color: #72849a;
  height: 45px;
}
.work-tools-text-disabled .work-tools-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #d0d4d7;
}
.work-tools-text-disabled .work-tools-label {
  font-size: 0.9rem;
  font-weight: 400;
  color: #d0d4d7;
  height: 45px;
}
/**************/
.integration-text .integration-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #505c6a;
}
.integration-text .integration-sub-title {
  font-size: 0.7rem;
  font-weight: 400;
}
.integration-text .integration-label {
  font-size: 0.9rem;
  font-weight: 400;
  color: #72849a;
  height: 45px;
}
.integration-text-disabled .integration-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #d0d4d7;
}
.integration-text-disabled .integration-label {
  font-size: 0.9rem;
  font-weight: 400;
  color: #d0d4d7;
  height: 45px;
}
.integration-card-active {
  border-color: rgb(82 196 2 / 65%) !important;
  border-width: 1px;
  background-color: rgb(82 196 33 / 4%);
}
.integration-card-integration-inactive {
  border-color: rgba(196, 26, 26, 0.4) !important;
  border-width: 1px;
  background-color: rgba(196, 26, 26, 0.02);
}
.integration-card-double-invoicing {
  border-color: #e03535 !important;
  border-width: 2px;
}
.integration-card-inactive .ant-card-body {
  border-width: 2px;
  margin: 1px;
}
/***************/
.work-tool-text-disabled h4,
.work-tool-text-disabled span {
  color: #d0d4d7 !important;
}
.sp-button {
  color: #fff;
  border-color: #fff !important;
  background: #fff !important;
  box-shadow: #fff 0px 0px 0px 1px inset !important;
}
.sp-button:hover {
  color: #fff;
  border-color: #f3856a !important;
  background: #f3856a !important;
}
.timeline-content-div {
  border: 1px solid #ebebeb;
  padding: 6px;
  border-radius: 4px;
}
.timeline-item-div {
  margin-bottom: 8px;
}
.listElementHover .ant-list-item:hover {
  background: #fafafa;
}
.card-featured {
  border: 2px solid #ed6c4d;
}
.student-course-item-image {
  height: 40px;
  margin-right: 8px;
  border-radius: 6px;
}
.__json-pretty__ {
  border-radius: 0.225rem;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bundle-item-selected {
  background: #f4fff2;
}
.bundle-item-draft {
  background: #fafafa;
}
.bundle-item-error {
  background: #ffecec;
}
.bg-bundle {
  background-color: #fffbfa;
  border: 1px solid #fad9d1 !important;
}
.bg-co-production {
  background-color: #fdfaff;
  border: 1px solid #e0d1fa !important;
}
.products-ownership-tab .ant-tabs-tab-btn {
  font-size: 1rem !important;
}
.products-ownership-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
}
/* table tr.ant-table-expanded-row > td {
  border: 1px solid rgb(230, 230, 230) !important;
} */
.table-comments-expanded {
  padding-left: 50px;
}
.table-comments-expanded table {
  border-radius: 0px !important;
  border-left: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
}
.table-comments-expanded table,
.table-comments-expanded table .ant-table-thead > tr > th {
  background-color: #f5f5f5 !important;
}
/* .text-danger {
  color: #ff6b72 !important;
} */
/**
*
*
*

TIDIO
*
*
*
*
*/
/* 
#tidio-chat-iframe {
  inset: auto -11px -13px auto !important;
}
#tidio-chat-iframe iframe {
  z-index: 99 !important;
}
#tidio-chat-iframe #button:not(.sidebar) .buttonWave::after {
  width: 48px !important;
  height: 48px !important;
}
#tidio-chat-iframe #button:not(.sidebar) .buttonWave {
  width: 48px !important;
  height: 48px !important;
}

#tidio-chat-iframe #button-body {
  width: 48px !important;
  height: 48px !important ;
} */
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  overflow: overlay;
}
/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 16px;
  height: 16px;
  z-index: 999999;
}
/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  border: 4px solid #fff;
}
/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: 4px solid #fff;
}
/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}
#chatbase-bubble-button {
  bottom: 0.5rem !important;
  right: 0.5rem !important;
}
#chatbase-bubble-button #chatIcon {
  stroke: white !important;
}
.dark-background .ant-upload-text {
  color: #fff !important;
}
.ant-table-thead > tr > th,
.ant-table-small .ant-table-thead > tr > th {
  background-color: #fbfcfc !important;
}
.student-course-item-image-details {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}

.classes-card {
  width: 100%;
  max-height: 110px;
  height: 110px;
}
.classes-card-background {
  background-image: url(/public/img/classeboard.png);
  background-repeat: no-repeat;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-size: cover;
  height: 100%;
  border-top-left-radius: 0.225rem;
  border-top-right-radius: 0.225rem;
}
.classes-card:hover {
  cursor: pointer;
}
.classes-card > h3 {
  color: #ffffff !important;
}
.classes-card:hover > h3,
.classes-card-background:hover > h3 {
  color: #053149 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5) !important;
}
.classes-card .classes-card-background {
  opacity: 1;
}
.classes-card:hover > .classes-card-background {
  opacity: 0.9 !important;
}
.classes-card h3 {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: #ffffff !important;
}
.classes-card h3:hover {
  cursor: pointer;
  color: #053149 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5) !important;
}
/**
*******************************************************************************
*******************************************************************************
*******************************************************************************
*/

.batches-card {
  width: 100%;
  max-height: 110px;
  height: 110px;
}
.batches-card-background {
  background-image: url(/public/img/batchboard.png);
  background-repeat: no-repeat;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-size: cover;
  height: 100%;
  border-top-left-radius: 0.225rem;
  border-top-right-radius: 0.225rem;
}
.batches-card:hover {
  cursor: pointer;
}
.batches-card > h3 {
  color: #ffffff !important;
}
.batches-card:hover > h3,
.batches-card-background:hover > h3 {
  color: #300549 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5) !important;
}
.batches .batches-card-background {
  opacity: 1;
}
.batches-card:hover > .batches-card-background {
  opacity: 0.9 !important;
}
.batches-card h3 {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0px;
  right: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: #ffffff !important;
}
.batches-card h3:hover {
  cursor: pointer;
  color: #300549 !important;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5) !important;
}

/* .users-students-actions-list {
  display: table-caption;
} */
/* .users-students-actions-list button {
  margin-bottom: 10px;
} */
#tidio-chat-iframe {
  inset: auto -11px -13px auto !important;
}
#tidio-chat-iframe iframe {
  z-index: 99 !important;
}
#tidio-chat-iframe #button:not(.sidebar) .buttonWave::after {
  width: 48px !important;
  height: 48px !important;
}
#tidio-chat-iframe #button:not(.sidebar) .buttonWave {
  width: 48px !important;
  height: 48px !important;
}
#tidio-chat-iframe #button-body {
  width: 48px !important;
  height: 48px !important ;
}
.form-item-checkbox .ant-form-item-label {
  padding-bottom: 0px !important;
}
.form-item-checkbox .ant-form-item-control-input {
  max-height: 28px !important ;
  min-height: 28px !important ;
}
.ant-table-pagination {
  align-items: center;
}
.onboarding-modal .ant-modal-body {
  padding: 0px !important;
}
.ideias-drawer .ant-drawer-body {
  padding: 0px !important;
}
.card-dates-interval button {
  font-size: 11px;
}
.ant-btn-sm-space {
  border: 1px solid transparent;
  height: 36px;
  padding: 6.5px 0.8rem;
  font-size: 14px;
}
.shortener-dark-link {
  color: #000 !important;
}
.shortener-dark-link:hover {
  color: #000 !important;
  text-decoration: underline;
}
.shortener-card-tags {
  display: inline-flex;
}
.shortener-card-tags .shortener-card-add-tags {
  visibility: hidden;
  padding-left: 8px;
}
.shortener-card-tags:hover .shortener-card-add-tags {
  visibility: visible;
  padding-left: 8px;
}
/**
*
* GIST
*
*/
/* .gist-messenger-bubble-iframe {
  height: 50px !important;
  width: 50px !important;
  min-height: 50px !important;
  min-width: 50px !important;
}

.gist-messenger-bubble-iframe iframe .gist-inner-launcher {
  width: 50px !important;
  height: 50px !important;
}

.gist-messenger-bubble-iframe iframe .gist-inner-launcher .gist-chat-icon .is-loaded svg {
  width: 23px !important;
  height: 23px !important;
} */
.ql-clipboard {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
#toolbar {
  background-color: #fff;
}
#sentry-feedback {
  --submit-border: #ee6b4c !important;
  --submit-background: #ee6b4c !important;
  --submit-background-hover: #f8866c !important;
  --submit-border-hover: #f8866c !important;
}
.card-sp .ant-card-body .ant-form-item:last-child {
  margin-bottom: 0 !important;
}
.card-marketplace-page .ant-card-head-title {
  font-size: 20px;
  font-weight: 400;
  color: #505c6a;
}
.card-marketplace-page .ant-form-item-label label {
  font-size: 16px;
  font-weight: 500;
}
.collapse-marketplace-page .ant-collapse-header,
.collapse-marketplace-page .ant-collapse-arrow {
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #505c6a !important;
}
.topic-array-list .ant-list-item {
  padding: 8px 8px !important;
}
.onboarding-card-cover .ant-card-cover {
  border-bottom: 1px solid #e6ebf1 !important;
  border-top-right-radius: 0.225rem !important;
  border-top-left-radius: 0.225rem !important;
  cursor: "pointer";
}
.onboarding-card-cover .ant-card-cover img {
  margin-left: 1px !important;
  padding-right: 2px !important;
  margin-top: 1px !important;
}
.form-item-checkbox .ant-form-item-label {
  padding-bottom: 0px !important;
}
.table-comments-deleted {
  background-color: #ffeeee !important;
}
.table-comments-deleted .ant-table-cell-row-hover {
  background-color: #ffe6e6 !important;
}
.helper-copyable-text {
  font-size: 16px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.helper-card {
  background-color: #e5faff !important;
  border-width: 1px !important;
  border-color: #5ebcd3 !important;
  width: 100% !important;
}
#groove-container-40602295-496f-4554-a81e-18d709cc9419 {
  bottom: 0px !important;
  right: 0px !important;
}
@media only screen and (max-width: 1500px) {
  .cards-top .ant-card-body {
    padding: 10px;
  }
  .cards-top .card-top-title {
    font-size: 14px;
  }
  .cards-top h1 {
    line-height: 25px;
  }
  .cards-top h1 span {
    font-size: 20px !important;
  }
  .cards-top span {
    font-size: 14px !important;
  }
  .cards-top .text-gray-light {
    font-size: 11px !important;
  }
  .cards-top .card-top-title-suffix span {
    font-size: 10px !important;
  }
  .cards-top .card-top-2ndline {
    margin-top: 5px !important;
  }
}
.ant-upload.ant-upload-select-picture-card img {
  border-radius: 10px;
}
.ant-upload.ant-upload-drag {
  padding: 0px 0px;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 0px 0px !important;
}
.ant-upload.ant-upload-drag .ant-upload img {
  border-radius: 0.225rem;
}
.modal-video-help .ant-modal-body {
  padding: 0px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
/* .modal-video-help iframe {
  border: 0px;
  border-radius: 12px;
} */
.react-player {
  overflow: hidden !important;
  padding-bottom: 56.25% !important;
  position: relative !important;
  height: 0 !important;
}
.react-player iframe {
  left: 0 !important;
  top: 0 !important;
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
}
.ant-drawer-footer {
  padding: 10px 10px 10px 10px !important;
}
.staff-2fa-container {
  width: 100% !important;
  max-width: 600px !important;
}
.phone-input-2fa {
  text-align: center;
}
.phone-input-2fa input {
  text-align: center !important;
  font-size: 1.5rem !important;
  width: 80% !important;
  max-width: 200px !important;
}
.monthly-revenue-list-item {
  font-size: 75%;
}
.table-row-red {
  background-color: rgba(255, 0, 0, 0.03) !important;
}
.table-row-red:hover,
.table-row-red:hover > td {
  background-color: rgba(255, 0, 0, 0.04) !important;
}

.sub-table-transactions .ant-table {
  border-radius: 0px !important;
  border-left: 1px solid #e6ebf1 !important;
}
.sub-table-transactions .ant-table-content table {
  border-radius: 0px !important;
}

.sub-table-transactions .ant-table-thead tr th {
  background-color: #455560 !important;
  color: #fff;
  border-radius: 0px !important;
}

/*******************************************************************/
/*******************************************************************/
/*******************************************************************/
/*******************************************************************/

.sub-table-movements .ant-table {
  border-radius: 0px !important;
  border-left: 1px solid #e6ebf1 !important;
}
.sub-table-movements .ant-table-content table {
  border-radius: 0px !important;
}

.sub-table-movements .ant-table-thead tr th {
  background-color: #455560 !important;
  color: #fff;
  border-radius: 0px !important;
}

.panel-title {
  font-size: 26px !important;
  font-weight: 400 !important;
  color: #494949 !important;
}

.marketplace-panel-info-draft {
  background-color: #efefef !important;
  border-color: #d6d6d6 !important;
}
.marketplace-panel-info-approved {
  background-color: #f7fff5 !important;
  border-color: #d9ffb8 !important;
}

.marketplace-panel-info-published {
  background-color: #edffe8 !important;
  border-color: #c0f793 !important;
}

.marketplace-panel-info-rejected {
  background-color: #ffebeb !important;
  border-color: #ffb8b8 !important;
}
.marketplace-panel-info-review {
  background-color: #fff5e4 !important;
  border-color: #ffe3b3 !important;
}

.collapse-affiliates .ant-collapse-header,
.collapse-affiliates .ant-collapse-arrow {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #505c6a !important;
}

.collapse-affiliates-panel .ant-collapse-header {
  /* padding-top: 10px !important;
  padding-bottom: 10px !important; */

  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
