.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.c-ant-card-hoverable {
  transition: box-shadow 0.3s, border-color 0.3s;
}
.c-ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px -2px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px,
    rgba(0, 0, 0, 0.09) 0px 5px 12px 4px;
}
.c-products-list-item {
  color: #1a3353 !important;
}
.c-products-list-item:hover {
  color: #3e79f7;
}
.c-pointer {
  cursor: pointer;
}
.c-selected-card {
  border: 2px solid rgb(98 173 82);
  box-shadow: rgba(98, 173, 82, 0.16) 0px 1px 2px -2px, rgba(98, 173, 82, 0.12) 0px 3px 6px 0px,
    rgba(98, 173, 82, 0.09) 0px 5px 12px 4px;
  background-color: rgba(98, 173, 82, 0.04);
}
.ant-form-item-explain {
  font-size: 12px !important;
  color: rgb(158 166 177);
  font-style: italic;
}
[class*="globalClass_"] [class*="logo_"],
[class*="leaf_"] [class*="cssLeaf_"],
.leaf_d36d._left_a113 .cssLeaf_b69c,
.leaf_d36d._right_d626 .cssLeaf_b69c {
  display: none !important;
}
[class*="globalClass_"] [class*="label_"] [class*="_bottom_"] {
  min-width: 100px !important;
}
[class*="globalClass_"] [class*="label_"][class*="_bottom_"],
[class*="globalClass_"] [class*="label_"][class*="_right_"] {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
[class*="globalClass_"] [class*="label_"][class*="_bottom_"] [class*="text_"],
[class*="globalClass_"] [class*="label_"][class*="_left_"] [class*="text_"],
[class*="globalClass_"] [class*="label_"][class*="_right_"] [class*="text_"] {
  margin: 0 10px 0 10px !important;
  min-width: 100px;
}
[class*="globalClass_"] [class*="leaf_"][class*="_bottom_"],
[class*="globalClass_"] [class*="leaf_"][class*="_left_"],
[class*="globalClass_"] [class*="leaf_"][class*="_right_"] {
  width: 0px !important;
}
[class*="globalClass_"] [class*="copy_"] {
  display: none;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
[class*="globalClass_"] [class*="label_"] [class*="text_"] {
  line-height: 28px !important;
}
[class*="globalClass_"] [class*="label_"] {
  height: 25px !important  ;
}
[class*="globalClass_"] .__jivoMobileButton {
  zoom: 0.7 !important;
}
[class*="globalClass_"] #jvlabelWrap {
  bottom: 130px !important;
}
.loader,
.loader:after {
  border-radius: 50%;
  height: 6.5em;
  width: 6.5em;
}
.loader-logo {
  margin-top: 60px;
  text-align: center;
}
.loader {
  animation: load8 1.1s linear infinite;
  border: 0.8em solid hsla(0, 0%, 43%, 0.2);
  border-left-color: #868686;
  font-size: 11px;
  margin: 20px auto 25px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-msg {
  color: #8d8d8d;
  font-size: 14px;
}
.loader-msg,
.loader-version {
  text-align: center;
  text-transform: uppercase;
}
.loader-version {
  color: #c9c9c9;
  font-size: 10px;
}
body {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
/* Safari, Chrome and Opera > 12.1 */
/* Internet Explorer */
/* Opera < 12.1 */
.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-bottom: 0px !important;
  border-radius: 6px 6px 0px 0px !important;
}
.ql-editor {
  min-height: 250px;
  overflow: visible !important;
}
.ql-container {
  border-radius: 0px 0px 6px 6px !important;
}
.ql-tooltip {
  left: 0 !important;
}
.pulsingButton {
  box-shadow: 0 0 0 0 rgba(62, 121, 247, 0.4);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}
.pulsingButton:hover {
  animation: none;
}
.pulsingButtonOnboarding {
  animation: pulseOnboarding 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 rgba(0, 132, 255, 0.4);
}
.pulsingButtonOnboarding:hover {
  animation: none;
}
/* Animation */
@keyframes pulse {
  to {
    box-shadow: 0 0 0 25px rgba(0, 153, 255, 0);
  }
}
@keyframes pulseOnboarding {
  to {
    box-shadow: 0 0 0 10px rgba(0, 153, 255, 0);
  }
}
.player-wrapper {
  /* position: relative;
  padding-top: 56.25%; */
  /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.ant-upload.ant-upload-drag {
  min-height: 180px !important;
}
.course-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.course-cover img {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background-position: center;
}
.color-picker .color-picker-dropdown .color {
  height: 25px !important;
  width: 25px !important;
}
.color-picker .color-picker-dropdown {
  margin-right: 8px !;
}
.product-managment-rules {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-item-limiter {
  width: inherit;
  max-width: 630px;
}
.form-item-limiter .ant-form-item-control-input-content {
  max-width: 630px;
}
.clickable {
  cursor: pointer;
}

.page-header-title {
  margin-top: 0px !important;
  font-size: 18px;
  margin-bottom: 90px !important;
}
/**** TIMELINE OVERRIDE ****/
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(20% - 12px) !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  left: 20% !important;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(20% - 4px) !important;
  width: calc(80% - 14px) !important;
}
.sms-editor-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 354px;
}
#noticeable-widget iframe {
  top: -20px !important;
  right: 9px !important;
}
.widget-header-main-title {
  margin-top: 20px !important;
}
/********************************************************************************/
/* UPDATE NOTIFICATION */
.update-notification-popup {
  position: fixed;
  bottom: 24px;
  left: 24px !important;
  box-shadow: 0px 0px 20px 6px rgba(184, 184, 184, 0.5);
  border-radius: 6px;
  background-color: #fff;
  padding: 18px;
  display: flex;
  max-width: calc(100vw - 48px);
  width: 300px;
  z-index: 9999;
  background-image: url(/public/img/update_background.png);
  background-size: cover;
  border: 2px solid #f5daee;
}
.update-notification-popup button {
  background-color: #2977f6;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 0 16px;
  font-size: 0.9rem;
  height: 34px;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}
.update-notification-popup button {
  cursor: pointer;
}
.update-notification-popup p {
  font-size: 0.8rem;
  line-height: 0.95rem;
}
.update-notification-popup svg {
  color: #cc85b3;
}
.product-links-transfer .ant-transfer-list {
  height: 300px;
  width: 45%;
}
.card-is-dragging {
  border: 2px dashed #ed6c4c !important;
}

.card-order-bump-item .ant-card-body {
  padding-top: 5px !important;
}

.btn-transactions {
  width: 30px !important;
  height: 30px !important;
  padding: 5.5px 0px;
  font-size: 13px;
  border-radius: 0.225rem;
}

.btn-movements {
  width: 30px !important;
  height: 30px !important;
  padding: 5.5px 0px;
  font-size: 13px;
  border-radius: 0.225rem;
}

.payment-method-row label {
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  margin-bottom: 5px;
}

.affiliate-market-card {
  margin: 5px;
  margin-bottom: 30px;
}

.affiliate-market-card .ant-card-body {
  padding: 15px !important;
  /* padding-top: 8px !important; */
}
.affiliate-market-card .ant-card-bordered .ant-card-cover {
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.affiliate-market-card .max-price {
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 10px;
}
.affiliate-market-card .affiliates-count {
  font-size: 0.7rem;
  font-weight: 400;
}

.affiliate-market-card .affiliation-not-set {
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  vertical-align: top;
}

.affiliate-market-card-promoted {
  border-color: #ee6b4c;
  padding: 1px;
}
.affiliate-market-card-promoted:hover {
  border-color: #ee6b4c;
  padding: 1px;
}

.affiliate-details-separator {
  margin-left: 10px;
  padding-right: 10px;
  border-right: 1px solid #e8e8e8;
}

.affiliate-market-drawer-max-price,
.affiliate-market-drawer-max-commission {
  text-align: center;
}

.affiliate-market-drawer-max-price div,
.affiliate-market-drawer-max-commission div {
  font-size: 0.9rem;
  font-weight: 600;
}

.affiliate-market-drawer-max-price h4 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 0px;
}

.affiliate-market-drawer-max-commission h4 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0px;
  /* color: #1dad3f; */
}

.affiliate-details-items {
  text-align: center;
}

.affiliate-details-items span {
  font-size: 0.8rem;
}

.affiliate-drawer-bottom-content {
  /* // Fade animation */
  animation: fadein 1s;
}
.affiliates-avatar-right {
  left: -10px;
}
/* .affiliate-market-card {
  
  animation: fadein 0.5s;
} */

.xmas_cap::before {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 51px;
  left: 15px;
  top: 2px;
  z-index: 999;
  background: url(assets/images/xmas_cap.png) left top / contain no-repeat;
}

.dashboard-carousel {
  margin-bottom: 15px;
}

.dashboard-carousel .ant-carousel .carousel-img {
  border-radius: 0.225rem;
}
.affiliates-commission-amount {
  color: #138f5b !important;
  font-weight: 700 !important;
}
.form-item-helper {
  line-height: 90% !important;
  margin-top: 2px !important;
}
.nav-search-dropdown .search-list-item {
  display: flex;
  align-items: flex-start !important;
  padding: 0.4375rem 0.75rem;
}

/*************************************************************************************************
**************************************************************************************************
*  AI BUTTON
**************************************************************************************************
***************************************************************************************************/

.btn-ai-button button {
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 6px;
  --style-box-shadow-spread: 1px;
  --style-box-shadow-color: rgba(91, 0, 255, 0.5) !important;
  box-shadow: var(--style-box-shadow-style-type) var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px) var(--style-box-shadow-spread, 0px)
    var(--style-box-shadow-color, #000000) !important;
  background: linear-gradient(
    135deg,
    rgb(69, 196, 249) 0%,
    rgb(125, 9, 255) 48%,
    rgb(255, 11, 229) 100%
  ) !important;
  border-color: #ffffff !important;
  border-width: 0px !important;
}

.btn-ai-button button,
.btn-ai-button button:active,
.btn-ai-button button:visited,
.btn-ai-button button:focus {
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 6px;
  --style-box-shadow-spread: 1px;
  --style-box-shadow-color: rgba(91, 0, 255, 0.5) !important;
  box-shadow: var(--style-box-shadow-style-type) var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px) var(--style-box-shadow-spread, 0px)
    var(--style-box-shadow-color, #000000);
  background: linear-gradient(135deg, rgb(69, 196, 249) 0%, rgb(125, 9, 255) 48%, rgb(255, 11, 229) 100%);
  border-color: #ffffff !important;
  border-width: 0px !important;
}

.btn-ai-button button:hover {
  background: linear-gradient(
    125deg,
    rgb(81, 196, 245) 0%,
    rgb(134, 31, 252) 48%,
    rgb(253, 23, 230) 100%
  ) !important;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 8px;
  --style-box-shadow-spread: 3px;
}

.btn-ai,
.btn-ai:active,
.btn-ai:visited,
.btn-ai:focus {
  --style-box-shadow-style-type: ;
  --style-box-shadow-distance-x: 0px;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 6px;
  --style-box-shadow-spread: 1px;
  --style-box-shadow-color: rgba(91, 0, 255, 0.5) !important;
  box-shadow: var(--style-box-shadow-style-type) var(--style-box-shadow-distance-x, 0px)
    var(--style-box-shadow-distance-y, 0px) var(--style-box-shadow-blur, 0px) var(--style-box-shadow-spread, 0px)
    var(--style-box-shadow-color, #000000) !important;
  background: linear-gradient(
    135deg,
    rgb(69, 196, 249) 0%,
    rgb(125, 9, 255) 48%,
    rgb(255, 11, 229) 100%
  ) !important;
  border-color: #ffffff !important;
  border-width: 0px !important;
}

.btn-ai:hover {
  background: linear-gradient(
    125deg,
    rgb(81, 196, 245) 0%,
    rgb(134, 31, 252) 48%,
    rgb(253, 23, 230) 100%
  ) !important;
  --style-box-shadow-distance-y: 0px;
  --style-box-shadow-blur: 8px;
  --style-box-shadow-spread: 3px;
}

/*************************************************************************************************
Should be used in div (not button
***************************************************************************************************/

@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}
.btn-ai-2 {
  animation: spin 2.5s infinite linear paused;
  background:
  /* Background colors don’t work with `background-origin`, so use a gradient. */ linear-gradient(
        to bottom,
        oklch(0.1 0.2 240 / 0.85),
        oklch(0.1 0.2 240 / 0.85)
      )
      padding-box,
    /* ends at inner border edges */
      conic-gradient(from var(--bg-angle) in oklch longer hue, oklch(0.85 0.37 0) 0 0) border-box; /* extends to outer border edges */

  /* a clear border lets the background gradient shine through */
  border: 4px solid transparent;

  /* unpause the animation on hover */
  &:hover {
    animation-play-state: running;
  }
}

/********************************************************************************************************/

@keyframes beat {
  to {
    transform: scale(1.15);
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ai-stars-icon {
  vertical-align: text-top;
}
.ai-stars-icon:disabled {
  cursor: not-allowed;
}

.ai-stars-icon svg {
  animation: beat 0.35s infinite alternate;
}

/* .ai-stars-icon svg path {
  fill: linear-gradient(135deg, rgb(69, 196, 249) 0%, rgb(125, 9, 255) 48%, rgb(255, 11, 229) 100%);
} */

.ai-start-v2 {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: var(--border-1x) solid transparent;
  background: linear-gradient(var(--constant-light), var(--constant-light)) padding-box,
    linear-gradient(318.89deg, #1696ff 14.62%, #9c71ff 35.6%, #f8649b 61.05%, #ff6f51 83.82%) border-box;
}

._iPMRw._ljQrm {
  overflow: visible;
}

._iPMRw._ljQrm path:nth-child(1) {
  animation: _T5eBl 2s ease-in-out forwards;
  transform-origin: 17px 6px;
}

._iPMRw._ljQrm path:nth-child(2) {
  animation: _RxliO 2s ease-in-out forwards;
  transform-origin: 4.5px 4.5px;
}

._iPMRw._ljQrm path:nth-child(3) {
  animation: _Y3ljY 2s ease-in-out forwards;
  transform-origin: 20px 21px;
}

@keyframes _T5eBl {
  0% {
    transform: rotate(0deg) scale(1);
  }

  10% {
    transform: rotate(90deg) scale(2.4);
  }

  20% {
    transform: rotate(90deg) scale(1);
  }

  100% {
    transform: rotate(90deg) scale(1);
  }
}

@keyframes _RxliO {
  0% {
    transform: rotate(0deg) scale(1);
  }

  13% {
    transform: rotate(90deg) scale(1.5);
  }

  23% {
    transform: rotate(90deg) scale(1);
  }

  100% {
    transform: rotate(90deg) scale(1);
  }
}

@keyframes _Y3ljY {
  0% {
    transform: rotate(0deg) scale();
  }

  15% {
    transform: rotate(90deg) scale(1.7);
  }

  25% {
    transform: rotate(90deg) scale(1);
  }

  100% {
    transform: rotate(90deg) scale(1);
  }
}

/********************************************************************************/
/********************************************************************************/
/********************************************************************************/
/********************************************************************************/

.msger {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;

  height: calc(100% - 50px);
  min-height: 400px;
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  /* box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2); */
}

.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}
.ai-chat-container {
  height: 550px;
  overflow: auto;
  /* Firefox scrollbar */
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  /* Webkit scrollbar (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  /* For IE */
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.msger-chat {
  flex: 1;
  background-color: #f2f0e9;
  padding: 10px;
}
.msger-chat-modal .ant-modal-body {
  background-color: #f2f0e9;
  padding: 0px !important;
}

.msger-chat-modal .ant-modal-body .ant-input-search .ant-input:hover {
  border-width: 1px;
  border-color: #fff;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.msg-text {
  font-size: 14px;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 100%;
  padding: 15px;
  border-radius: 15px;
  width: 100%;
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}
.left-msg {
  padding: 10px;
  display: flex;
  align-items: baseline;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid E8E6DC;
}
.right-msg {
  padding: 10px;
  display: flex;
  align-items: baseline;
  background-color: #e7e5db;
  border-radius: 5px;
  border: 1px solid #dbd8c7;
}
.right-msg .msg-bubble {
  color: #585858;
  border-bottom-right-radius: 0;
}

.bouncing-dots {
  display: flex;
  justify-content: space-between;
  width: 35px;
}
.msg-feedback {
  margin-top: 10px;
  /* padding-top: 8px; */
  /* border-top: #dadada 1px dashed; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 85%;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #ee6b4c;
  border-radius: 50%;
  animation: bounce 0.8s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

/*******************************************************************************
  ******************************************************************************/

:root {
  --rainbowangle: 45deg;
}
@property --rainbowangle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
@keyframes rainbowRotate {
  to {
    --rainbowangle: 360deg;
  }
}

.rainbow {
  border-radius: 10px;

  --border-size: 0.3rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(
      from var(--rainbowangle),
      #db4338 0deg 90deg,
      #bc3127 90deg 180deg,
      #a72920 180deg 270deg,
      #97241b 270deg 360deg
    )
    1 stretch;
}

.rainbow {
  animation: rainbowRotate 4s linear infinite;
}

:root {
  --end-of-year-dashed: #971a1a;
}
.end-of-year-border {
  background-image: linear-gradient(90deg, var(--end-of-year-dashed) 50%, transparent 50%),
    linear-gradient(90deg, var(--end-of-year-dashed) 50%, transparent 50%),
    linear-gradient(0deg, var(--end-of-year-dashed) 50%, transparent 50%),
    linear-gradient(0deg, var(--end-of-year-dashed) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1.5s infinite linear;
}
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

/********************************************************************************/
.admin-menu .ant-menu-item,
.admin-menu .ant-menu-inline .ant-menu-submenu-title,
.admin-menu .ant-menu-inline .ant-menu-root {
  height: 30px !important;
  font-size: 85% !important;
  line-height: 30px !important;
}
.admin-menu .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 4px !important;
}

.admin-menu .ant-menu-submenu {
  font-size: 85% !important;
}

.ant-switch.kill-switch-switch:not(.ant-switch-checked) {
  background: #ff0000 !important;
}
.kill-switch-table .ant-table-tbody .ant-table-cell-row-hover {
  background: #ffdada !important;
}

.kill-switch-table .ant-table-title {
  background-color: #e8e8e8 !important;
}

.kill-switch-table .ant-table-thead .ant-table-cell {
  background-color: #fafafa !important;
}
.red-span {
  color: #ff0000 !important;
  font-weight: 600 !important;
}

.kill-switch-warning-void {
  padding: 3px;
}
.kill-switch-warning {
  background-image: linear-gradient(90deg, var(--end-of-year-dashed) 50%, transparent 50%),
    linear-gradient(90deg, var(--end-of-year-dashed) 50%, transparent 50%),
    linear-gradient(0deg, var(--end-of-year-dashed) 50%, transparent 50%),
    linear-gradient(0deg, var(--end-of-year-dashed) 50%, transparent 50%) !important;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1.5s infinite linear;
  padding: 3px;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}
