/* 
*
* Shared with AppV2 
*
*/

.order-bump-container {
  width: 100%;
  max-width: 665px;

  padding: 25px;
  margin-top: 20px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* .order-bump-container .arrow-wrapper {
  display: none;
} */

/*
Arrow Anime
*/

.arrow-wrapper {
  display: inline-flex;
  margin-right: 12px;
}

.arrow-item {
  width: 50px;
  height: 12px;
  position: relative;
}

.arrow1 {
  animation: slide1 1s ease-in-out infinite;
  margin-left: 9px;
}

@keyframes slide1 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(10px, 0);
  }
}

:root {
  --order-bump: #971a1a;
}
.active-animation {
  background-image: linear-gradient(90deg, var(--order-bump) 50%, transparent 50%),
    linear-gradient(90deg, var(--order-bump) 50%, transparent 50%),
    linear-gradient(0deg, var(--order-bump) 50%, transparent 50%),
    linear-gradient(0deg, var(--order-bump) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 3px, 15px 3px, 3px 15px, 3px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 1.5s infinite linear;
}
@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }
  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px, right top 15px;
  }
}

.arrows {
  width: 12px;
  height: 12px;
  display: inline-flex;
  border-color: #000;
}

.prev {
  border-bottom: 4px solid;
  border-left: 4px solid;
  transform: rotate(45deg);
}

.next {
  border-bottom: 4px solid;
  border-left: 4px solid;
  transform: rotate(-135deg);
  right: 10px;
}

.order-bump-container .title {
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
  /* color: #455560;  */
}
.order-bump-container .description {
  padding-top: 15px;
  font-size: 15px;
  font-weight: 400;
}

.order-bump-container .button {
  padding-top: 15px;
  text-align: center;
}

.order-bump-container .pricing {
  font-size: 0.8rem;
  padding-top: 5px;
  text-align: center;
  font-weight: 600;
  /* color: rgb(66, 66, 66); */
}

.order-bump-container .submit-button {
  white-space: break-spaces;
  /* background-color: #a33cb9;
  border-color: #9336a7; */
  font-size: 16px;
  font-weight: 600;
  height: auto;
  padding: 8px 20px 8px 8px;
  border-radius: 0.28571429rem;
  box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}

.order-bump-container .submit-button:hover {
  /* background-color: #ae4ac4;
  border-color: #a23cb9; */
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .order-bump-container .submit-button {
    white-space: break-spaces;
    /* background-color: #a33cb9;
    border-color: #9336a7; */
    font-size: 14px;
    font-weight: 600;
    height: auto;
    padding: 8px 20px 8px 8px;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
  }

  .order-bump-container .submit-button:hover {
    /* background-color: #ae4ac4;
    border-color: #a23cb9; */
    font-size: 15px;
  }

  .order-bump-container .title {
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 600;
  }
  .order-bump-container .description {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
  }
  .order-bump-container .pricing {
    font-size: 0.8rem;
    padding-top: 5px;
    text-align: center;
    font-weight: 400;
    /* color: rgb(66, 66, 66); */
  }
}
